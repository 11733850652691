import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { apiPaths } from 'modules/defines/paths';
import http from './axios';

const fileAPI = {

  upload: (rqData, file, cookies) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.fileUpload, { 
        headers: { 
          'Content-type': 'application/json',
          'Authorization': `bearer ${cookies.user.token}` }, 
        data: JSON.stringify(rqData),
       })
        .then(response => {
          const request = response.data.map((data, i) => {
            return new Promise((resolve, reject) => { 
              http('', data.presignedPath, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/sla',
                },
                data: file[i],
                timeout: 0,
              }).then(
                response => {
                  resolve(response);
                },
                error => {
                  console.log(error);
                  reject(error);
                },
              );
            });
          });
          Promise.all(request)
            .then(response => {
              resolve(response);
            })
            .catch(error => reject(error));
        });
    });
  },

  delete: (data, cookies) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.fileDelete, { 
        method: 'POST',
        headers: {
          'Authorization': `bearer ${cookies[0].user.token}`,
        },
        data })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },

  init: (data) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.initData, { 
        method: 'POST',
        headers: {
          // 'Authorization': `bearer ${cookies.token}`,
        },
        data })
        .then((response) => { resolve(response);})
        .catch(error => { reject(error); });
    });
  },

  update: (data, cookies) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.update, { 
        method: 'POST',
        headers: {
          'Authorization': `bearer ${cookies.user.token}`,
        },
        data })
        .then((response) => { resolve(response);})
        .catch(error => { reject(error); });
    });
  },

  save: (data) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.saveData, { 
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        data })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },

  load: (data) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.loadData, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `bearer ${cookies.token}`,
        }, data: JSON.stringify(data) })
        .then((response) => { resolve(response); })
        .catch(e => { reject(e); });
    });
  },

  bulkDownload: (data, cookies) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.bulkDownload, { 
        method: 'POST',
        headers: {
          'Authorization': `bearer ${cookies.user.token}`,
        },
        data })
        .then((response) => { resolve(response);})
        .catch(error => { reject(error); });
    });  
  },

  combinedDownload: (data, cookies) => {
    return new Promise((resolve, reject) => {
      http('', apiPaths.combinedDownload, { 
        method: 'POST',
        headers: {
          'Authorization': `bearer ${cookies.user.token}`,
        },
        data })
        .then((response) => { resolve(response);})
        .catch(error => { reject(error); });
    });  
  },


  

  //STL url 로드 api
  getSTL: (stld) => {
    // load IDed STL mesh
    const loader = new STLLoader();
    return new Promise((resolve, reject) => {
       const host = process.env.NODE_ENV === 'development' ? 'https://test.node.3d-labeling.ddhinc.net' : 'https://test.node.3d-labeling.ddhinc.net';

      loader.load(`${host}${apiPaths.getSTL}?path=${stld}`, (geometry) => {
        if (geometry) {
          resolve(geometry);
        } else {
          reject();
        }
      });
    });
  },
};

export default fileAPI;